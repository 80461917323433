import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ProductionSiteLightModelExtended } from '../../models/ProductionSiteLightModelExtended';
import { ActivitiesRequestArgs } from '../../services/dataContracts/controller/ActivitiesRequestArgs';
import { ActivityRequestArgs } from '../../services/dataContracts/controller/ActivityRequestArgs';
import { EditProductionSiteLogisticUnitRequestArgs } from '../../services/dataContracts/controller/EditProductionSiteLogisticUnitRequestArgs';
import { ProductionSitesReferentialApiClient } from '../../services/ProductionSitesReferentialApiClient';
import './EditProductionSiteDialogStyles.scss';

interface EditProductionSiteDialogComponentProperties {
    productionSiteToEdit: ProductionSiteLightModelExtended,
    editLogisticUnit: (productionSiteChoiceLogisticsUnits: EditProductionSiteLogisticUnitRequestArgs) => void,
    onClose: () => void
}

interface activity {
    index: number;
    level2ActivityId: string;
    level2ActivityLabel: string;
    businessUnitId: string;
    isDeleted?: boolean;
}

export const EditProductionSiteDialogComponent = (props: EditProductionSiteDialogComponentProperties): JSX.Element => {
    const productionSite = props.productionSiteToEdit;

    const [newLines, setNewLines] = useState<activity[]>([]);
    const [activities, setActivities] = useState<{ id: string, label: string }[]>([]);
    const [productionSiteActivities, setProductionSiteActivities] = useState<activity[]>([]);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [businessUnitIds, setBusinessUnitIds] = useState<string[]>([]);

    useEffect(() => {
        let productionSiteActivities: activity[] = [];
        productionSite.productionSiteActivities.forEach((productionSiteActivity, index) => {
            productionSiteActivities.push({
                index: index,
                level2ActivityId: productionSiteActivity.level2ActivityId,
                level2ActivityLabel: productionSiteActivity.level2ActivityLabel,
                businessUnitId: productionSiteActivity.businessUnitId,
                isDeleted: false
            })
        });
        setProductionSiteActivities(productionSiteActivities);

        Promise.all([
            ProductionSitesReferentialApiClient.GetActivities(),
            ProductionSitesReferentialApiClient.GetBusinessUnitIds()
        ]).then((results) => {
            let activitiesData = results[0].data;
            let activitiesOptions: { id: string, label: string }[] = [];
            activitiesData.forEach((activity) => {
                activitiesOptions.push({
                    id: activity.level2ActivityId,
                    label: activity.label
                });
            });

            setActivities(activitiesOptions);
            setBusinessUnitIds(results[1].data)
        });

    }, [props.productionSiteToEdit])

    const handleAddNewLine = (): void => {
        let lines = [...newLines];

        lines.push({
            index: lines.length,
            level2ActivityId: "-1",
            level2ActivityLabel: "",
            businessUnitId: ""
        });

        setNewLines(lines);
    }

    const handleDeleteNewLine = (index: number): void => {
        let lines = [...newLines];
        let lineIndex = lines.findIndex(x => x.index === index);
        let newBusinessUnitIds: string[] = [];
        if (lineIndex !== -1) {
            newBusinessUnitIds = businessUnitIds.filter(x => x !== lines[lineIndex].businessUnitId);
            lines.splice(lineIndex, 1);
        }

        setNewLines(lines);
        setBusinessUnitIds(newBusinessUnitIds);
    }

    const handleDeleteOldLine = (index: number): void => {
        let lines = [...productionSiteActivities];
        let lineIndex = lines.findIndex(x => x.index === index);
        lines[lineIndex].isDeleted = true;

        setProductionSiteActivities(lines);
        setHasChanges(true);
    }

    const handleSelectActivity = (activity: string, index: number): void => {
        let lines = [...newLines];
        let lineIndex = lines.findIndex(x => x.index === index);
        lines[lineIndex].level2ActivityId = activity;
        lines[lineIndex].level2ActivityLabel = activity === "-1" ? "" : activities.find(x => x.id === activity).label;

        setNewLines(lines);
    }

    const handleBuChanged = (bu: string, index: number): void => {
        let lines = [...newLines];
        let lineIndex = lines.findIndex(x => x.index === index);
        lines[lineIndex].businessUnitId = bu;

        setNewLines(lines);
    }

    const handleSave = (): void => {
        let upActivities = [...productionSiteActivities].filter(x => !x.isDeleted).concat([...newLines]);

        let listOfActivities: ActivityRequestArgs[] = [];
        upActivities.forEach((a) => {
            listOfActivities.push({
                level2ActivityId: a.level2ActivityId,
                businessUnitId: a.businessUnitId
            });
        });

        let activitiesRequestArgs: ActivitiesRequestArgs = {
            activities: listOfActivities
        }

        ProductionSitesReferentialApiClient.AddOrUpdateProductionSiteActivities(productionSite.productionSiteId, activitiesRequestArgs)
            .then(() => {
                props.onClose();
            });
    }

    let regExp = /^[^\_]{12}$|^[^\_]{12}\_[^\_]{1,2}$/;
    let invalidateLines = newLines.some(x => x.level2ActivityId === "-1" ||
        !regExp.test(x.businessUnitId.trim()) ||
        businessUnitIds.includes(x.businessUnitId.trim()) ||
        newLines.find(l => l.businessUnitId.trim() === x.businessUnitId.trim() && l.index !== x.index) !== undefined);

    console.log(businessUnitIds);

    return (
        <Box display="flex" flexDirection="column" className="edit-Production-site">
            <Box display="flex" flexDirection="row">
                <div className="title">Unité de production : </div><div className="value">{productionSite.productionSiteId}</div>
                <div className="title">Nom : </div> <div className="value">{productionSite.label}</div>
            </Box>
            <Box className="activities">
                <Box display="flex" flexDirection="row" mb="10px" className="activity-item">
                    <div className="activity header">Activité</div>
                    <div className="label header">Libellé</div>
                    <div className="business-unit header">BU</div>
                </Box>
                {productionSiteActivities.filter(x => !x.isDeleted).map((activity, index) => {
                    return (
                        <Box key={`activity-${index}`} display="flex" flexDirection="row" className="activity-item">
                            <div className="activity">{activity.level2ActivityId}</div>
                            <div className="label">{activity.level2ActivityLabel}</div>
                            <div className="business-unit">{activity.businessUnitId}</div>
                            <IconButton size="small" aria-label="delete" onClick={() => handleDeleteOldLine(index)}
                                disabled={productionSiteActivities.filter(x => !x.isDeleted).length === 1 && newLines.length === 0}>
                                <FontAwesomeIcon className="fa-times" icon={faTimes} />
                            </IconButton>
                        </Box>
                    )
                })}
                {newLines.map((line, index) => {
                    return (
                        <Box key={`new-activity-${index}`} display="flex" flexDirection="row" className="activity-item">
                            <div className="activity">
                                <select className="select" onChange={(e) => handleSelectActivity(e.target.value, line.index)} value={line.level2ActivityId}>
                                    <option value="-1" ></option>
                                    {activities.map((activity, index) => {
                                        return (
                                            <option key={`activity-option-${index}`} value={activity.id}
                                                disabled={productionSiteActivities.find(x => x.level2ActivityId === activity.id) !== undefined || newLines.find(x => x.level2ActivityId === activity.id) !== undefined}
                                            >
                                                {activity.id}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="label">{line.level2ActivityLabel}</div>
                            <div className="business-unit">
                                <input type="text"
                                    className={!regExp.test(line.businessUnitId.trim()) || businessUnitIds.includes(line.businessUnitId.trim()) || newLines.find(l => l.businessUnitId.trim() === line.businessUnitId.trim() && l.index !== line.index) !== undefined ? "input error" : "input"}
                                    value={line.businessUnitId} onChange={(e) => handleBuChanged(e.target.value, line.index)} />
                            </div>
                            <IconButton size="small" aria-label="delete" onClick={() => handleDeleteNewLine(line.index)}
                                disabled={productionSiteActivities.filter(x => !x.isDeleted).length === 0 && newLines.length === 1}>
                                <FontAwesomeIcon className="fa-times" icon={faTimes} />
                            </IconButton>
                        </Box>
                    )
                })}
                <Box display="flex" flexDirection="row" justifyContent="flex-end" p="5px">
                    <IconButton size="small" aria-label="Add" onClick={handleAddNewLine}>
                        <FontAwesomeIcon className="fa-plus" icon={faPlus} />
                    </IconButton>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" p="5px">
                    <Button variant="contained" color="primary" title="Modifier"
                        onClick={handleSave} disabled={(hasChanges === false && newLines.length === 0) || invalidateLines}>
                        Modifier
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}